.details-panel {
  padding: 20px;
  background-color: #fff;
  width: 35vw;
  overflow: auto;
  height: 100vh;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.net-zero-emissions {
  margin-bottom: 20px;
}

.net-zero-emissions h2 {
  font-size: 1em;
}

.net-zero-emissions span {
  font-size: 0.7em;
}

.emissions-progress {
  display: flex;
  align-items: center;
}

.baseline, .target {
  font-size: 14px;
  color: #666;
}

.progress-bar {
  flex: 1;
  height: 25px;
  background-color: #e0e0e0;
  margin: 0 10px;
  border-radius: 10px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #ffc107;
  /*background-color: #0033a0;*/
  position: relative;
}

.progress::after {
  content: '';
  position: absolute;
  right: 0;
  width: 5px;
  height: 100%;
  /*background-color: #ffc107;*/
  background-color: #0033a0;
}

.region-summary {
  margin-bottom: 20px;
}

.summary-tabs {
  display: flex;
  margin-bottom: 20px;
}

.tab {
  flex: 1;
  padding: 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
}

.tab.active {
  background-color: #0033a0;
  color: #fff;
}

.emission-info, .additional-info, .energy-consumption {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 20px;
}

.net-zero-emissions, .region-info, .emission-card, .info-card, .consumption-card {
  background-color: #fafafa;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.emission-value, .info-value, .consumption-value {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.initiative-chart {
  height: 150px;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin-top: 10px;
}

.footer {
  text-align: center;
  font-size: 12px;
  color: #888;
}

.footer img {
  margin-left: 5px;
  vertical-align: middle;
}

.value-card {
  background-color: #fff;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left; /* Change from center to left */
  font-family: 'Arial', sans-serif;
  border: #eee 1px solid;
}

.value-card h4 {
  font-size: 12px;
  color: #333;
  margin: 0 0 4px; /* Adjust margin for better spacing */
}

.value-card p {
  font-size: 12px;
  color: #888;
  margin: 0;
}

.value-card .emission-value {
  font-size: 28px; /* Increase the font size for emphasis */
  font-weight: bold;
  color: #000;
  margin: 8px 0; /* Adjust margin for spacing */
}

.value-card .emission-value::after {
  font-size: 12px; /* Adjust font size for the unit */
  color: #888; /* Use a lighter color for the unit */
}

.reduction-initiatives {
  text-align: center;
  margin-bottom: 20px;
}

.reduction-initiatives .equivalent-value {
  color: #b78c1a;
  margin-top: 5px;
}

.reduction-initiatives .chart-container {
  position: relative;
  height: 200px;
  margin-top: 10px;
}

.initiative-chart {
  height: 100px;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin-top: 10px;
}

.chart-container {
  height: 200px;
  width: 100%;
}


.percentage {
  display: inline-block;
  padding: 5px 10px;
  background-color: #e6f7e6;
  color: #28a745;
  border-radius: 10px;
  font-weight: bold;
  text-align: center;
  float: right;
}

.percentage.better {
  background-color: #e6f7e6; /* Light green */
  color: #28a745; /* Green */
}

.percentage.worse {
  background-color: #ffe6e6; /* Light red */
  color: #dc3545; /* Red */
}

.percentage .arrow {
  margin-left: 5px;
}


.region-info {
  text-align: center;
  margin-bottom: 20px;
}

.region-info img {
  width: 80%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.region-info h3 {
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px 0;
}

.region-info p {
  font-size: 0.8em;
  color: #666;
  margin: 5px 0;
}

.region-info .details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.region-info .details p {
  margin: 2px 0;
}

.region-info .details strong {
  font-weight: bold;
  color: #333;
}

.branches-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.branch-card {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.branch-card h4 {
  margin-bottom: 5px;
  font-size: 0.8em;
}

.branch-card p {
  margin: 2px 0;
  font-size: 0.8em;
  color: #888888;
}

.branch-card p > span {
  margin: 2px 0;
  font-size: 1em;
  color: #000000;
}
