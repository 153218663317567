.container {
  display: flex;
  height: 100vh;
  width: 100%;
}

/* App.css or a relevant CSS file */
.map-container {
  flex: 1;
}

.leaflet-control-attribution {
  display: none;
}

.custom-popup .popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.custom-popup .popup-image {
  border-radius: 10px;
  margin-bottom: 10px;
}

.custom-popup .popup-text h3 {
  margin: 5px 0;
  font-size: 18px;
}

.custom-popup .popup-text p {
  margin: 3px 0;
  font-size: 14px;
  color: #555;
}

.custom-popup .more-detail-btn {
  background-color: #ffc107;
  color: #000;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.custom-popup .more-detail-btn:hover {
  background-color: #ffb300;
}
