.app-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content {
  display: flex;
  flex: 1;
}

.app {
  display: flex;
  overflow: hidden;
}
